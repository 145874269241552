body {
  box-sizing: border-box;
  background-color: #f6f6f6;
  text-align: justify;
  color: #494556;
  font-family: "Raleway", sans-serif;
}

.navbar {
  font-family: "Libre Baskerville", serif;
  position: fixed;
  top: 0%;
  z-index: 1;
  background-color: #7f7888dc;
  height: 40px;
  width: 100%;
  transform: translate(-8px, 0px);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  box-shadow: 0px 3px 10px #3a3939;
}

a {
  text-decoration: none;
  color: #ebeaf5;
  font-size: 1.2rem;
  padding: 1%;
}

a:hover {
  color: #231f2b;
  font-weight: 600;
  padding: none;
  cursor: pointer;
}

.hero-image {
  background-image: url(https://i.imgur.com/AQ6we5g.jpg);
  background-color: ##f6f6f6;
  height: 60vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.hero-text,
.statement {
  font-family: "Libre Baskerville", serif;
  background-color: #ffffffbf;
  position: absolute;
  left: 34%;
  top: 44%;
  padding-left: 2%;
  padding-right: 2%;
  font-weight: 100;
  line-height: 0.5rem;
  border-radius: 5px 5px;
}

h2 {
  font-family: "Libre Baskerville", serif;
  color: #373342;
}

.statement {
  font-family: "Raleway", sans-serif;
  width: 50%;
  line-height: 1.5rem;
  top: 70%;
}

.skills,
.projects {
  width: 100%;
  text-align: center;
}

.skills-section {
  border-top: 2px solid #7f7888;
  border-bottom: 2px solid #7f7888;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  background-color: #ffffff;
}

.skills-icon {
  width: 5vw;
  margin: 2vw;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.about {
  max-width: 90%;
  padding: 4%;
  padding-top: 0%;
  line-height: 1.5;
}

.flickr {
  font-size: 1em;
  color: #494556;
  padding: 0%;
  text-decoration: underline;
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  background-color: #afa5ae;
  margin: 0%;
  color: #ebeaf5;
}

.social-media {
  width: 20%;
  display: flex;
}

.contact-icons {
  width: 70%;
  margin-top: -10px;
}

.contact-icons:hover {
  transform: scale(1.2);
}

.made-by {
  width: 100%;
  text-align: center;
  margin-top: 10px;
  background-color: #7f7888;
}

.app-name {
  width: fit-content;
  padding-left: 3%;
  padding-right: 10%;
  background-image: linear-gradient(
    -100deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 0, 0.7) 95%,
    rgba(255, 255, 255, 0)
  );
}

.app,
.ibeen-app,
.llama-app,
.dategrub-app,
.easemart-app,
.tictactoe-app,
.sunny-app,
.miatelier-app {
  display: flex;
  font-size: 1rem;
  flex-direction: row;
  border-top: 2px solid #7f7888;
  background-color: #f6f6f6;
}

.miatelier-image {
  max-width: 100%;
}

.miatelier-app {
  border: none;
  flex-direction: row-reverse;
}

.llama-body {
  width: 50%;
}

.dategrub-app,
.easemart-app,
.app {
  background-color: #ffffff;
}

.app-body,
.sunny-body,
.miatelier-body {
  width: 85%;
  margin-left: 5%;
  margin-top: 3%;
  padding-right: 15px;
}

.dategrub-body {
  margin-right: 5%;
  margin-bottom: 2%;
}

.llama1-image {
  width: 80%;
  transform: translate(10%, 10%);
}

.llama2-image {
  width: 100%;
}

.dategrub-image {
  width: 100%;
  margin-top: 5%;
  transform: translate(-15%, 0px);
}

.ibeen-image {
  width: 90%;
  transform: translate(11%, 0%);
}

.ibeen-cell,
.llama3-image {
  display: none;
}

.easemart-image {
  margin: 6%;
  width: 100%;
}

.farmchoir-image {
  width: 100%;
  margin-top: 15%;
}

.tictactoe-image {
  width: 75%;
  margin: 10%;
}

.sunny-body {
  margin-right: 5%;
}

.sunny-image {
  width: 95%;
  margin: 3%;
  margin-left: 0%;
  transform: translate(0px, 20px);
}

.website-logo,
.git-logo {
  width: 2.5rem;
  margin: 1rem;
  margin-left: -0.5rem;
}

@media (max-height: 500px) {
  body {
    font-size: 1rem;
  }
  .hero-image {
    height: 250px;
  }
  .about {
    max-width: 95%;
    padding-left: 1%;
  }
}

@media (max-width: 640px) {
  body {
    font-size: 1rem;
  }
  .hero-image {
    height: 250px;
  }
  .about {
    max-width: 95%;
    padding-left: 1%;
  }
}

@media (max-height: 600px) {
  body {
    font-size: 1rem;
  }
  .hero-image {
    height: 250px;
  }
  .about {
    max-width: 95%;
    padding-left: 1%;
  }
}

@media (max-width: 640px) {
  body {
    font-size: 1rem;
  }
  a {
    font-size: 1rem;
    padding: 2%;
  }
  .hero-image {
    height: 250px;
  }
  .hero-text {
    top: 20%;
    left: 27%;
    line-height: 0.5rem;
  }
  .statement {
    top: 68%;
    left: 30%;
    width: 64%;
    font-size: 10px;
    line-height: 1rem;
  }
  .about {
    max-width: 95%;
    padding-left: 1%;
  }
  .skills-icon {
    width: 4em;
    margin: 1em;
  }
  .app,
  .dategrub-app,
  .easemart-app,
  .tictactoe-app,
  .miatelier-app {
    flex-direction: column;
  }
  .ibeen-app,
  .sunny-app {
    flex-direction: column-reverse;
  }
  .dategrub-image,
  .ibeen-cell,
  .farmchoir-image,
  .tictactoe-image {
    transform: translate(0px, 0px);
    width: 100%;
  }
  .llama-body {
    width: 100%;
    margin: 2%;
  }
  .llama-app {
    border: none;
  }
  .ibeen-image,
  .llama1-image,
  .llama2-image {
    display: none;
  }
  .ibeen-cell,
  .llama3-image {
    display: block;
    width: 100%;
    margin-bottom: 2%;
    margin-top: 2%;
  }
  .llama3-image {
    border-top: 2px solid #7f7888;
  }
  .sunny-image {
    width: 100%;
    margin: 0%;
    transform: translate(0px, 0px);
    margin-top: -5%;
  }
  .dategrub-body {
    transform: translate(0px, 0px);
    margin: 2%;
  }
  .easemart-image {
    margin: 0%;
    margin-top: 3%;
    /* margin-right: 0%; */
  }
  .tictactoe-image {
    margin: 0%;
    margin-bottom: 5%;
  }
  .social-media {
    width: 80%;
  }
  .contact-icons {
    width: 55%;
  }
}
